
body, html { 
  font-family: 'Poppins', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #334a74;


  h1 {
    margin: 0;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 48.3px;

    @media only screen and (min-width: 768px) {
      font-size: 42px;
    }
 
  }

  button {
    font-family: 'Poppins', sans-serif;
    display: block;
    margin: 0;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
